<template>
  <div class="sampledetail">
    <div v-if="dataList">
      <div class="title mt6">检测填报</div>
    <van-cell-group class="mt10">
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 0" value="待检测" />
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 1" value="检测中" />
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 2" value="初检完成" />
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 3" value="检测完成" />
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 4" value="让步审批中" />
      <van-field label="检测状态" input-align="right" readonly v-if="dataList.check_status == 5" value="审批完成" />
      <van-field label="报检类型" input-align="right" readonly :value="dataList.inspect.type.type_name" />
      <van-field label="报检编号" input-align="right" readonly :value="dataList.inspect.inspect_code" />
      <van-field label="报检时间" input-align="right" readonly :value="dataList.inspect.inspect_time" />
    </van-cell-group>
    <div class="title">报检员信息</div>
    <van-cell-group class="mt10">
      <van-field label="报检员ID" input-align="right"   readonly :value="dataList.inspect.inspect_emp_id" />
      <van-field label="报检员姓名" input-align="right" readonly :value="dataList.inspect.inspect_emp_name" />
      <van-field label="报检员部门" input-align="right" readonly :value="dataList.inspect.inspect_dep_name" />
    </van-cell-group>
    <div class="title">样品信息</div>
    <van-cell-group class="mt10">
      <van-field label="产品编号" input-align="right" readonly :value="dataList.inspect.sample_code" />
      <van-field label="产品名称" input-align="right" readonly :value="dataList.inspect.sample_name" />
      <van-field label="产品规格型号" input-align="right" readonly :value="dataList.inspect.sample_specs" />
      <van-field label="报检批次" input-align="right" readonly :value="dataList.inspect.inspect_batch" />
      <van-field label="产品供应商" input-align="right" readonly :value="dataList.inspect.supplier_name" />
      <van-field label="合同编号" input-align="right" readonly :value="dataList.inspect.contract_no" />
      <van-field label="原产品" input-align="right" readonly :value="dataList.inspect.original_product" />
      <van-field label="生产线" input-align="right" readonly :value="dataList.inspect.production_line" />
      <van-field label="报检重要说明" input-align="right" readonly :value="dataList.inspect.inspect_remark" />
    </van-cell-group>
    <div class="title">检测情况</div>
    <van-cell-group class="mt10">
      <van-field label="初检时间" v-show="dataList.check_status > 1" input-align="right" readonly :value="dataList.check_time" />     
      <!-- <van-field v-show="check_status !== 0"
        v-model="eqpValue"
        is-link
        readonly
        label="检测设备"
        placeholder="选择检测设备"
        @click="clickeqpPicker"
        input-align="right"
      />       -->
      <van-field-checkbox
        label="检测设备"
        placeholder="请选择"
        v-model="value2"
        :columns="eqpList"
        label-width="100"
        :option="{label:'equipmentName',value:'id'}"
        @confirm="confirm"
        @click="clickeqpPicker"
      /> 
       <!--选择设备  -->
       <van-popup v-model="showeqpPicker" round position="bottom">
        <!-- <van-picker
          show-toolbar
          title="选择检测设备"
          :columns="eqpList"
          value-key="equipment_name"
          @cancel="showeqpPicker = false"
          @confirm="eqpConfirm"        
        /> --> 
        <!-- <van-field-check-box
            v-model="eqpValue"
            placeholder="请选择"
            :columns="eqpList"
           
            @confirm="eqpConfirm"
        /> -->
         <!-- :option="{label:'equipment_name', value:'equipment_code'}" -->
       </van-popup>
      <van-field label="检样员"  input-align="right" v-show="dataList.check_status == 1" readonly :value="userName" />
      <van-field label="检样员"  input-align="right" v-show="dataList.check_status == 2" readonly :value="twoName" />
      <van-field label="是否复检" input-align="right" readonly v-show="dataList.is_inspect_recheck == 0" value="否" />
      <van-field label="是否复检" input-align="right" readonly v-show="dataList.is_inspect_recheck == 1" value="是" />
    </van-cell-group>
    <!-- <div class="title">
      检测项目  
    </div>  -->
    <van-row type="flex" justify="space-between">
      <van-col span="8">
        <van-cell center required title="检测项目"></van-cell>
      </van-col>        
      <van-col span="16" class="tar" v-if="dataList.check_status == 1">
        <van-cell>
          <van-tag class="add tr" type="primary" plain round size="medium" @click="addzb"><van-icon name="plus" size="13px" color="#3d7cf3" />新增指标</van-tag>
        </van-cell>
      </van-col>
    </van-row>
    <van-action-sheet v-model="upShow" title="检测值" v-if='option1.length > 0' class="elasticlayer" :actions="option1" @select="onSelect"  :overlay="false" style="height: 50%;" />
    <van-action-sheet v-model="upShow" v-else title="检测值" class="elasticlayer"  :overlay="false" style="height: 50%;" >
      <div class="content" style="text-align: center;">暂无数据</div>
    </van-action-sheet>
    <div style='position:relative;'>
      <!-- <img v-show="dataList.check_status > 1 && dataList.conclusion == 1" src="@/assets/hg.png" alt="合格" class="status" />
      <img v-show="dataList.check_status > 1 && dataList.conclusion == 0" src="@/assets/bhg.png" alt="不合格" class="status" />       -->
      <van-icon class="status" v-if="dataList.check_status > 1 && dataList.conclusion == 1" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
      <van-icon class="status" v-if="dataList.check_status > 1 && dataList.conclusion == 0" class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/>
      <ve-table 
          v-if="dataList.check_status == 1"                 
          rowKeyFieldName="rowKey"          
          :fixed-header="true"
          :columns="columns1"
          :table-data="tableData"
          :editOption="editOption"
          :rowStyleOption="rowStyleOption"
          border-y
          scrollWidth="720px"
      />
      <ve-table 
          v-else             
          rowKeyFieldName="rowKey"          
          :fixed-header="true"
          :columns="columns2"
          :table-data="tableData"
          :editOption="editOption"
          :rowStyleOption="rowStyleOption"
          border-y
          scrollWidth="720px"
      />
      <!-- :max-height="200"                       
      :virtual-scroll-option="virtualScrollOption" -->
    </div>
    <van-field label="初检说明"  input-align="right" v-model="cjMark" placeholder="请填写初检说明" />
    <van-cell center required title="总体结论">
      <template #right-icon>
        <span>{{conclusion === '0' ? '不合格' : '合格'}}</span>
        <van-switch class="ml5" v-model="conclusion" active-value="1" inactive-value="0" size="18" /> 
      </template>
    </van-cell>
    <div v-if="dataList.check_status == 1">
      <div class="title">复检人信息</div>
      <van-cell-group class="mt10" v-show="check_status !== 0">      
        <van-field  
          required    
          v-model="fjValue"
          is-link
          readonly
          label="复检人"
          placeholder="选择复检人"
          @click="getfjList"
          input-align="right"
        />     
      </van-cell-group>
    </div>
    <div v-else>
      <div class="title">复检人信息</div>
      <van-field label="复检人"  input-align="right" readonly :value="fjValue" />
      <van-field label="复检说明"  input-align="right" v-model="fjMark" placeholder="请填写复检说明" />      
    </div>
    <van-row gutter="20" class="btgroup" type="flex" justify="center">
      <van-col span="8"><van-button round block plain size="small" type="default" to='/analysislist'>返回列表</van-button></van-col>
      <van-col span="8" v-if="dataList.check_status == '1'"><van-button round block size="small" type="primary" native-type="submit"  @click="clicksubBTn('save')" v-track="{triggerType:'click', title:'检测保存',currentUrl: $route.path,behavior: dataList.inspect.inspect_code, duration: new Date().getTime()}">保 存</van-button></van-col>
      <van-col span="8" v-if="dataList.check_status == '1'"><van-button round block size="small" type="primary" native-type="submit"  @click="clicksubBTn('submit')" v-track="{triggerType:'click', title:'检测提交',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}">提 交</van-button></van-col>
      <van-col span="16" v-else><van-button round block size="small" type="primary" native-type="submit" @click="clicksubBTn('submit')" v-track="{triggerType:'click', title:'检测提交',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}">提 交</van-button></van-col>      
    </van-row>
    </div>
    <div v-else>
      <van-empty image="error" description="掐指一算，ID不对" />
      <van-row gutter="20" class="btgroup" type="flex" justify="center">
        <van-col span="12"><van-button round block plain size="small" type="default" to='/analysislist'>返回列表</van-button></van-col>
        <van-col span="12"><van-button round block size="small" type="primary" to='/qrcode'>返回扫码</van-button></van-col>
      </van-row>
    </div>

    <!-- 选择复检人 弹窗 -->
    <van-popup v-model="showfjPicker" round position="bottom">
      <van-picker
        show-toolbar 
        title="选择复检人"
        :columns="fjList"
        value-key="username"
        @cancel="showfjPicker = false"
        @confirm="fjConfirm"        
      />
    </van-popup>
    <!-- 是否合格 -->
    <van-popup v-model="showhgPicker" round position="bottom">
      <van-picker
        show-toolbar
        title="选择检测结果"
        :columns="hgList"
        value-key="value"
        @cancel="showhgPicker = false"
        @confirm="hgConfirm"        
      />
    </van-popup>
    <!-- 选择检测结果 弹窗 -->
    <van-popup v-model="optionSelect" round position="bottom">
      <van-picker
        show-toolbar
        title="选择检测结果"
        :columns="hgList"
        value-key="value"
        @cancel="optionSelect = false"
        @confirm="slectConfirm"        
      />
    </van-popup>
  </div>  
</template>
<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import VanFieldCheckbox from '../components/VanFieldCheckbox.vue'
import { Col, Row,Cell, CellGroup, Step, Steps, Toast, Field, Picker, Popup, Button, Icon, Switch, Tag, ActionSheet, Loading, Empty } from 'vant'

Vue.use(Col)
Vue.use(Row)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(ActionSheet)
Vue.use(Loading)
Vue.use(Empty)

export default {
  components: {
    VanFieldCheckbox
  },
  data() {
    return { 
      checked: true, 
      upShow: false,   
      option1: [],
      zbValue: '',   
      value2: [], // 选中的设备
      equipment_items: [],     
      check_status: '',
      inspect_id: '',
      virtualScrollOption: {
          // 是否开启
          enable: true,
      },      
      userName: '',
      uId: '',
      twoUid: '',
      twoName: '',
      dataList:{
        check_items:[],
        inspect:{
          type: {}
        }
      },
      eqpValue: '',
      eqpId: '',
      showeqpPicker: false,
      eqpList: [],
      cjhgValue: '',
      hgValue: '',
      hgId: '',
      conclusion: '1',
      showhgPicker: false,
      optionSelect: false,
      hgList: [{
        id: 1,
        value: '合格'
      },{
        id: 0,
        value: '不合格'
      }],   
      cjMark: '',  
      fjValue: '',
      fjMark: '',
      fjId: '',
      showfjPicker: false,
      fjList: [], 
      slectItem: '',   
      rowItem: [],
      columns1: [
          { field: "", key: "a", title: "序号", align: "center",width: "10%", renderBodyCell: ({ row, column, rowIndex }, h) => {
            return ++rowIndex;
          }},            
          { field: "check_name", key: "check_name", title: "检测项", align: "center"},
          { field: "check_standard", key: "check_standard", title: "参考指标", align: "center" },
          { field: "check_value", key: "check_value", title: "检测值", align: "center", edit:true },        
          { field: "single_conclusion", key: "single_conclusion", title: "单项结论", align: "center",width:'110px',         
            renderBodyCell: ({ row, column, rowIndex }, h) => {                                      
              return <div>
                      <span>{row.single_conclusion == '0' ? '不合格' : '合格'}</span>                     
                      <van-switch class="ml5" v-model={ row.single_conclusion } active-value="1" inactive-value="0" size="18" />
                    </div>                                       
            }            
          },
          { field: "", key: "e", title: "操作", align: "center",width:'80px',  
            renderBodyCell: ({ row, column, rowIndex }, h) => {                           
              return <van-button type="danger" round  size="mini" on-click={() => this.deleteRow(rowIndex)}>删 除</van-button>                                       
            }            
          },
      ],
      columns2: [
          { field: "", key: "a", title: "序号", align: "center",width: "10%", renderBodyCell: ({ row, column, rowIndex }, h) => {
            return ++rowIndex;
          }},            
          { field: "check_name", key: "check_name", title: "检测项", align: "center"},
          { field: "check_standard", key: "check_standard", title: "参考指标", align: "center" },
          { field: "check_value", key: "check_value", title: "检测值", align: "center", edit:true }, 
          { field: "single_conclusion", key: "single_conclusion", title: "单项结论", align: "center",width:'110px',
            renderBodyCell: ({ row, column, rowIndex }, h) => {                            
              return <div>
                      <span>{row.single_conclusion == '0' ? '不合格' : '合格'}</span>
                      <van-switch class="ml5" v-model={ row.single_conclusion } active-value="1" inactive-value="0" size="18" />
                    </div>                                       
            }            
          }         
      ],
      rowStyleOption: {
        clickHighlight: false,
      },
      editOption: {
        // cell value change
        cellValueChange: ({ row, column }) => {   
          this.tableData.forEach(ele => {
            if(ele.check_item_id == row.check_item_id) {
              ele.check_value = row.check_value
            }
          })    
        },          
      },      
      tableData: [],                             
    }
  },
  created: function () {     
    var id = ''; 
    if(this.$route.query.id) {       
      id = this.$route.query.id     
    } else {
      id = JSON.parse(localStorage.getItem('checkId'))    
    }   
    this.getDetail(id)
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {  
      this.uId = user.user_id.toString()
      this.userName = user.dd_data.dd_user_name        
    } else {
      this.asyncUser().then(res => { 
        this.uId = res.uid
        this.userName = res.dd_data.dd_user_name
      })      
    } 
  },
  watch:{ },
  mounted (){
    this.clickeqpPicker()    
  },
  methods: {
    ...mapActions('listData', ['asyncsetSampleType']),
    ...mapActions('router', ['asyncUser']),
    getDetail(id) {
      var that = this
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.$post('check/get-check-sample-info', 'api', { id: id}).then(res => {  
        Toast.clear()
        var data = res.data         
        this.inspect_id = data.inspect_id
        this.check_status = data.check_status
        this.twoUid = data.check_emp_id
        this.twoName = data.check_emp_name
        data.inspect.inspect_time = that.$common.initDate(data.inspect.inspect_time)
        data.check_time = that.$common.initDate(data.check_time)
        data.inspect_recheck_time = that.$common.initDate(data.inspect_recheck_time)    
        this.dataList = data
        this.eqpId = data.equipment_id
        data.check_items.forEach((ele,index) => {
          ele.rowKey = index
          ele.single_conclusion = ele.single_conclusion + ''
          // ele.check_value = ele.check_standard    
        })   
        if(data.check_status == 1) {
          this.hgId = ''
          this.hgValue = ''
          this.cjhgValue = ''
          // this.value2 = []          
        } else {
          this.conclusion = data.conclusion
          if(data.conclusion == '0') {
            this.hgValue = '不合格'   
            this.hgId = '0'
          } else {
            this.hgValue = '合格'
            this.hgId = '1'
          }    
          data.equipment_items.forEach(ele => {                       
            this.value2.push(ele.equipment_id) 
            this.equipment_items.push(ele)                    
          })        
        }  
        if(data.is_inspect_recheck == '1') {
          data.equipment_items.forEach(ele => {                       
            this.value2.push(ele.equipment_id) 
            this.equipment_items.push(ele)                    
          })
        }
        this.cjMark = data.check_remark
        this.fjMark = data.recheck_remark
        this.fjId = data.recheck_emp_id
        this.fjValue = data.recheck_emp_name 
        this.eqpValue = data.equipment_name        
        this.tableData = data.check_items          
        return        
      }, err => {
        console.log(err)
        this.dataList = ''
        // Toast.fail(err.message);
        Toast.clear()
        // this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 输入
    inputValue(val) {
      // console.log(val)
    },
    // 确认设备
    confirm (data1, data2) { // select确定,
      var equipmentItem = []
      data2.forEach(ele => {
        if(ele.qc_code) {
          equipmentItem.push({
            equipment_id: ele.id,
            equipment_name: ele.equipment_name+'-'+ele.qc_code
          })
        } else {
          equipmentItem.push({
            equipment_id: ele.id,
            equipment_name: ele.equipment_name
          })
        }        
      })      
      this.equipment_items = equipmentItem
    },
    // 点击选择设备
    clickeqpPicker () {      
      var that = this
      var params = {
        page: 1,
        offset: 100,
        equipment_name: ''
      }
      // this.showeqpPicker = true
      this.$post('equipment/get-equipment-list','api',params).then(res => {
        var data = res.data.list        
        data.forEach(ele => {
          ele.equipmentName = ele.equipment_name+'-'+ele.qc_code
        })
        that.eqpList = data
      }, err => {
        console.log(err)
      })
    },
    // 选择检测设备
    eqpConfirm(item) {
      this.eqpId = item.id
      this.eqpValue = item.equipment_name
      this.showeqpPicker = false
    },
    // 选择是否合格 
    hgConfirm(item) {
      this.hgId = item.id
      this.cjhgValue = item.value
      this.hgValue = item.value
      this.showhgPicker = false
    },
    // 自动判断单项是否合格
    autoCheck(standard, Fill) {
      const fillVal = parseFloat(Fill)
      // console.log(fillVal);
      if (standard.includes('≥')) {
        const val = parseFloat(standard.split('≥')[1])
        if (fillVal >= val) {
          return true
        } else {
          return false
        }
      } else if (standard.includes('≤')) {
        const val = parseFloat(standard.split('≤')[1])
        if (fillVal <= val) {
          return true
        } else {
          return false
        }
      } else if (standard.includes('=')) {
        const val = parseFloat(standard.split('=')[1])
        // console.log(val);
        if (fillVal === val) {
          return true
        } else {
          return false
        }
      }
    },
    // 获取复检人
    getfjList () {
      var that = this
      var params = {
        page: 1,
        offset: 100,
        username: '',
        is_accept_auth: '',
        is_recheck_auth: 1
      }
      this.showfjPicker = true
      var newArr = []
      this.$get('user/get-user-list','api',params).then(res => {
        var data = res.data.data  
        data.forEach(ele => {            
          if(ele.username == that.userName) {
            return
          } else {
            newArr.push(ele)
          }
        })  
        that.fjList = newArr
      }, err => {
        console.log(err)
      })
    },
    // 选择复检人
    fjConfirm (item) { 
      this.fjId = item.uid           
      this.fjValue = item.username
      this.showfjPicker = false
    }, 
    selectOption(item) { 
      this.rowItem = item
      this.optionSelect = true
    },
    slectConfirm (item) { 
      this.tableData.forEach(ele => {
        if(ele.check_item_id == this.rowItem.check_item_id) {
          ele.single_conclusion = item.value
        }
      })
      this.optionSelect = false
      this.slectItem = item.value
      // this.tableData[1].single_conclusion = '合格'
    },
    // 提交
    clicksubBTn (val) {                   
      var that =this    
      var id = ''
      if(this.$route.query.id) {       
        id = this.$route.query.id     
      } else {
        id = JSON.parse(localStorage.getItem('checkId'))    
      }     
      var check_status = this.check_status
      var params = {}        
      if(val == 'submit') {        
        if(check_status == '1' || check_status == '0') {
          let flag = false
          for (let i = 0; i < this.tableData.length; i++) {          
            if(this.tableData[i].check_value.trim() == '') {
              flag = false          
              this.$notify({ type: 'warning', message: this.tableData[i].check_name+'的检测值不能为空'})
              return false
            }  else {
              flag = true
            }      
          } 
          params = {
            id: id,
            inspect_id: this.inspect_id,
            check_emp_id: this.uId,
            check_emp_name: this.userName,
            recheck_emp_id: this.fjId,
            recheck_emp_name: this.fjValue,
            check_status: 2,
            conclusion: this.conclusion,
            check_items: this.tableData,
            equipment_items: this.equipment_items,
            is_publish: '0',
            check_remark: this.cjMark
          }
        }else if(check_status == '2' || check_status == '3') {          
          params = {
            id: id,
            inspect_id: this.inspect_id,
            check_emp_id: this.twoUid,
            check_emp_name: this.twoName,
            check_status: 3,
            conclusion: this.conclusion,
            check_items: this.tableData,
            equipment_items: this.equipment_items,
            recheck_remark: this.fjMark,
            is_publish: '0',
            check_remark: this.cjMark
          } 
        } 
      } else if (val == 'save') {        
        params = {
          id: id,
          inspect_id: this.inspect_id,
          check_emp_id: this.uId,
          check_emp_name: this.userName,
          recheck_emp_id: this.fjId,
          recheck_emp_name: this.fjValue,
          check_status: 2,
          conclusion: this.conclusion,
          check_items: this.tableData,
          equipment_items: this.equipment_items,
          is_publish: '1',
          check_remark: this.cjMark
        }
      }           
      if(params.conclusion === '') {
        // Toast.fail('检测结果不能为空');
        this.$notify({ type: 'warning', message: '检测结果不能为空' })
      } else {
        this.$post('check/check-sample', 'api', params).then(res => {                             
          this.$notify({ type: 'success', message: res.message })
          that.$router.push({
            path: '/analysislist'            
          })               
        }, err => {
          console.log(err)
          // Toast.fail(err.message);
          this.$notify({ type: 'warning', message: err.message })
        })
      }                                  
    },
    // 删除检测项
    deleteRow(rowIndex) {      
      this.tableData.splice(rowIndex, 1);
      this.tableData.forEach((ele,index) => {
        ele.rowKey = index
      })      
    },
    addzb() { 
      this.upShow = true
      var newList = []
      var params = {
        sample_code: this.dataList.inspect.sample_code
      }
      this.$get('check/get-product-check-items', 'api', params).then(res => {
        var checkList = this.tableData           
        var data = res.data.list            
        data.forEach(ele => {
          ele.name = ele.check_name          
        });  
        for(var i = 0;i < data.length; i++) {   
          var flag = false                 
          for(var j = 0;j < checkList.length; j++) {            
            if(checkList[j].check_item_id == data[i].check_item_id){  
              flag = true
            }                         
          } 
          if(!flag) {
            newList.push(data[i])
          }
        }   
        
        this.option1 = newList
      }, err => {
        console.log(err)     
      })  
    },
    // 选择下拉
    onSelect(item) {       
      this.tableData.unshift({
        check_item_id: item.check_item_id,
        check_name: item.check_name,
        check_standard: item.check_standard,
        check_value: "",
        rowKey: this.tableData.length,
        single_conclusion: '1'
      })
      this.upShow = false       
    },
  }

}
</script>

<style scoped>
.status{
  position: absolute;
  top: -30px;
  right: 0px;
  width: 80px;
  height: 80px;
  z-index: 999;
 }
.sampledetail{
  padding-bottom: 60px;
  padding-top: 10px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}
.btgroup{
  margin: 10px 12px;
}
</style>
